
const PROVIDER_TYPE_NONE = "none";
const PROVIDER_TYPE_COINBASE_WALLET = "coinbase_mobile";
const PROVIDER_TYPE_IM_TOKEN = "im_token";
const PROVIDER_TYPE_META_MASK = "meta_mask";

function debug(...args) {
  if (process.env.NODE_ENV !== 'production') {
    console.log(...args);
  }
}

// This error message is returned when a user hits "cancel" on MetaMask
function isUserCancel(error) {
  const errorMessage = error.message.toString();
  return (errorMessage.startsWith("Returned error: Error: MetaMask Tx Signature: User denied") || errorMessage.startsWith("Returned error: Error: MetaMask Message Signature: User denied"));
}

function providerType(provider) {
  if (provider === undefined || provider == null) {
    return PROVIDER_TYPE_NONE;
  } else if (provider.isToshi) {
    return PROVIDER_TYPE_COINBASE_WALLET;
  } else if (provider.isImToken) {
    return PROVIDER_TYPE_IM_TOKEN;
  } else if (provider.isMetaMask) {
    return PROVIDER_TYPE_META_MASK;
  } else {
    return null;
  }
}

export {
  debug,
  isUserCancel,
  providerType,
  PROVIDER_TYPE_NONE,
  PROVIDER_TYPE_COINBASE_WALLET,
  PROVIDER_TYPE_IM_TOKEN,
  PROVIDER_TYPE_META_MASK
};