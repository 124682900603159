
/*
 * `decimals` returns the number of decimals in a given ERC-20 token
 */
function getDecimals(app, assetAddress) {
  // For now, all decimals have 18 decimals
  return 18;
}

export {
  getDecimals
}