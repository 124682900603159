const SESSION_ID_KEY = "sessionId";
const SESSION_HAS_ERRORS_KEY = "sessionHasErrors";

let storage;
if (false && !!window.sessionStorage) {
  storage = window.sessionStorage;
} else {
  const myStorage = {}; // just use a simple global variable to capture session data if not exists otherwise.
  storage = {
    getItem: (key) => myStorage[key],
    setItem: (key, value) => myStorage[key] = value
  };
}

export function handleError(message, source, lineno, colno, error) {
  let report;
  if (error && error.stack) {
    report = reformatStack(error);
  } else {
    // throws without an error object (eg throw "yeet" vs throw new Error("yeet")
    // do not have an error object with a parseable stack, so just do bare minimum
    report = `${message} \n at ${source}:${lineno}:${colno}`;
  }

  notifyServer("error", {message: report});
  // session error metric
  let sessionHasErrors = storage.getItem(SESSION_HAS_ERRORS_KEY);
  let sessionId = currentSessionId();

  if(!sessionHasErrors) {
    storage.setItem(SESSION_HAS_ERRORS_KEY, true);
    notifyServer("session_error", {sessionId: sessionId});
  }
}

function reformatStack(error) {
  const FIREFOX_SAFARI_FUNCTION_REGEXP = /((.*".+"[^@]*)?[^@]*)(?:@)/;
  const FIRST_LINE_OF_V8_STACK_REGEXP = /^Error:/;
  let lines;
  if (error.stack && !error.stack.match(FIRST_LINE_OF_V8_STACK_REGEXP)) {
    lines =
        error.stack
        .split("\n")
        .map((line) => {
          var matches = line.match(FIREFOX_SAFARI_FUNCTION_REGEXP);
          var functionName = matches && matches[1] ? matches[1] : "";
          var location = line.replace(FIREFOX_SAFARI_FUNCTION_REGEXP, '');
          return `   at ${functionName} (${location})`;
        })
        .join("\n");

    return `${error.toString()}\n${lines}`;
  } else {
    // v8 stack is already what we want
    return error.stack;
  }

  // safari & ff look like this and stackdriver wont read
  // this function reformats safari stacks to look like v8 stacks
  // subscribeToMoneyMarketPorts@http://localhost:3000/static/js/bundle.js:73369:18
  // subscribe@http://localhost:3000/static/js/bundle.js:74075:30
  // http://localhost:3000/static/js/bundle.js:73092:28


  // chrome && brave look like this and stackdriver can handle it as is
  // Error: boom
  // at subscribeToMoneyMarketPorts (http://localhost:3000/static/js/bundle.js:73369:9)
  // at Object.subscribe (http://localhost:3000/static/js/bundle.js:74075:3)
  // at http://localhost:3000/static/js/bundle.js:73092:19
}

export function startSession() {
  if(!currentSessionId()) {
    setSessionId();
    let sessionId = currentSessionId();
    notifyServer("session_start", {sessionId: sessionId});
  }
}

function currentSessionId() {
  return storage.getItem(SESSION_ID_KEY);
}


function setSessionId() {
  let sessionId = generateSessionId();
  storage.setItem(SESSION_ID_KEY, sessionId);
}

function generateSessionId() {
  // just a random string
  let uniqueId = Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
  return uniqueId;
}

function notifyServer(event, payload) {
  let baseUrl = process.env.ANALYTICS_BACKEND_URL;
  let paths =
      {
        "session_start": `/dapp/session/${payload["sessionId"]}`,
        "session_error": `/dapp/session/${payload["sessionId"]}/error`,
        "error": "/dapp/error"
      };
  let url = baseUrl + paths[event];

  fetch(url, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    method: 'post',
    body: JSON.stringify(payload)
  }).catch((fetchError) => {
    console.error(`Error fetching ${url}: ${fetchError.message}`);
  });
}

