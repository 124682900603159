
// Global delay helps bunching by giving all requests from Elm time to come in before we start
// dispatching. It can also be used for slowing down responses for testing.
const GLOBAL_DELAY = 100;

// This is if we should add jitter, for testing.
const GLOBAL_DELAY_RAND = false;

function delay(result) {
  if (GLOBAL_DELAY) {
    return new Promise((accept, reject) => {
      var delay;

      if (GLOBAL_DELAY_RAND) {
        delay = Math.floor(Math.random() * GLOBAL_DELAY);
      } else {
        delay = GLOBAL_DELAY;
      }

      setTimeout(function() {
        accept(result);
      }, delay);
    });
  } else {
    return Promise.resolve(result);
  }
}

export {
  delay
}