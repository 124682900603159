import BN from '../../node_modules/bn.js';
import MoneyMarket from './json/contracts/MoneyMarket.json';
import Liquidator from './json/contracts/Liquidator.json';
import FaucetToken from './json/contracts/FaucetToken.json';
import EIP20Interface from './json/contracts/EIP20Interface.json';
import WETH9 from './json/contracts/WETH9.json';
import {
  getRandomInt,
  parseWeiStr,
  realToBigNumberScaled,
  toScaledDecimal
} from './math';
import { getDecimals } from './tokens';
import trxStorage from './trxStorage';
import storage from './storage';
import electionStorage from './electionStorage';
import { requestForeground } from './helpers';
import { handleError } from './analytics';
import { debug, isUserCancel } from './utils';
import bunch from './bunch';
import { delay } from './delay';
import {
  getAccounts,
  getBalance,
  getBlockNumber,
  getEvent,
  getNetworkId,
  getTransactionReceipt,
  makeEth,
  setNetworkId,
  sign,
  wrapCall,
  wrapSend
} from './eth';

const ACCOUNT_CHECK_INTERVAL_MS = 2000;
const NETWORK_CHECK_INTERVAL_MS = 4000;
const NEW_BLOCK_CHECK_INTERVAL_MS = 5000;
const BLOCKS_PER_YEAR = new BN(2102400); // (365 * 24 * 60 * 60) seconds per year / 15 seconds per block
const ETH_DECIMALS = 18;
const EXP_DECIMALS = 18;
const CALCULATE_ACCOUNT_VALUES_DECIMALS = 36;
const EXP_SCALE_BN = new BN(10).pow(new BN(18)); // 1e18 used for BN.div
const defaultCallParams = { gas: 1.0e10 };

const transactionStorage = trxStorage("transactions");
const preferencesStorage = storage("preferences");
const termsAcceptedStorage = storage("tosAcceptance");
const electionsVoteStorage = electionStorage("electionsVoteStorage");

// We wait this amount of milliseconds before informing Elm of a new block
// we've heard about from Web3. This is to give Infura time to clear caches
// and reduce the likelihood of getting stale data.
const NEW_BLOCK_DELAY = 1500;

var currentSendGasPrice;
var failureEvent;
var borrowLiquidatedEvent;

function reportError(app) {
  return (error) => {
    // TODO call window.on_error() with the stuff
    app.ports.giveError.send(error.toString());
  }
}

async function handleReceipt(app, trxHash, blockNumber, receipt) {
  // Ignore missing receipts or receipts that are beyond our knowledge of the
  // latest block (this is to provide consistency with the rest of the UI)
  if (!receipt || receipt.blockNumber > blockNumber) {
    return null;
  } else {
    const status = receipt.status === true ? 1 : 0;

    const failures = receipt.logs.map((log) => {
      if (failureEvent && failureEvent.matches((log))) {
        return failureEvent.decode(log);
      }
    }).filter((log) => !!log);

    var error = null;

    if (failures[0]) {
      // TODO: failure.info
      // TODO: failure.detail
      error = failures[0].error.toString(); // TODO: This should be a number
    }

    //Is this a liquidate successful event that we can send amount seized data with?
    const borrowLiquidatedLogs = receipt.logs.map((log) => {
      if (borrowLiquidatedEvent && borrowLiquidatedEvent.matches((log))) {
        return borrowLiquidatedEvent.decode(log);
      }
    }).filter((log) => !!log);

    var logsToSend = [];
    if(borrowLiquidatedLogs[0]) {
      var logArgs = [];
      for (var i = 0; borrowLiquidatedLogs[0][i] !== undefined; i++) {
        logArgs[i] = borrowLiquidatedLogs[0][i].toString();
      }
      logsToSend.push({event: "BorrowLiquidated", args: logArgs});
    }

    // TODO: Here we should really add a check for storing events on the transaction...
    app.ports.giveUpdateTrxPort.send({
      trxHash: trxHash,
      status: status,
      error: error,
      receiptLogs: logsToSend
    });
  }
}

function subscribeToMoneyMarketPorts(app, eth) {
  // port askMoneyMarketGetInterestRatesPort : { blockNumber : Int, contractAddress : String, assetAddress : String } -> Cmd msg
  app.ports.askMoneyMarketGetInterestRatesPort.subscribe(({blockNumber, contractAddress, assetAddress}) => {
    wrapCall(
      app,
      eth,
      [ [ MoneyMarket, contractAddress, 'markets', [assetAddress] ]
      , [ EIP20Interface, assetAddress, 'balanceOf', [contractAddress] ]
      ],
      blockNumber
    ).then(([marketResult, cashResult, equityResult]) => {
      const decimals = getDecimals(app, assetAddress);

      app.ports.giveMoneyMarketGetInterestRatesPort.send({
        assetAddress: assetAddress,
        supplyInterestRate: toScaledDecimal(parseWeiStr(marketResult.supplyRateMantissa).mul(BLOCKS_PER_YEAR), EXP_DECIMALS),
        borrowInterestRate: toScaledDecimal(parseWeiStr(marketResult.borrowRateMantissa).mul(BLOCKS_PER_YEAR), EXP_DECIMALS),
        totalSupply: toScaledDecimal(parseWeiStr(marketResult.totalSupply), 0),
        totalBorrows: toScaledDecimal(parseWeiStr(marketResult.totalBorrows), 0),
        totalCash: toScaledDecimal(parseWeiStr(cashResult), 0)
      });
    }).catch(reportError(app));
  });

  // port askMoneyMarketGetCustomerBalancePort : { blockNumber : Int, moneyMarketAddress : String, customerAddress : String, ledgerAccount : Int, assetAddress : String } -> Cmd msg
  app.ports.askMoneyMarketGetCustomerBalancePort.subscribe(({blockNumber, moneyMarketAddress, customerAddress, ledgerAccount, assetAddress}) => {
    const decimals = getDecimals(app, assetAddress);

    var checkpointedBalanceFunction;
    var mostRecentBalanceFunction;

    // TODO: Uhhhh, 2?
    if (ledgerAccount == 2) {
      checkpointedBalanceFunction = `supplyBalances`;
      mostRecentBalanceFunction = `getSupplyBalance`;
    } else {
      checkpointedBalanceFunction = `borrowBalances`;
      mostRecentBalanceFunction = `getBorrowBalance`;
    }

    wrapCall(
      app,
      eth,
      [
        [
          MoneyMarket,
          moneyMarketAddress,
          checkpointedBalanceFunction,
          [customerAddress, assetAddress]
        ],
        [
          MoneyMarket,
          moneyMarketAddress,
          mostRecentBalanceFunction,
          [customerAddress, assetAddress]
        ]
      ],
      blockNumber,
      `askMoneyMarketGetCustomerBalancePort-${customerAddress}-${assetAddress}-${checkpointedBalanceFunction}`
    ).then(([checkpointedBalanceResult, recentBalanceResult]) => {
      const checkpointedBalance = toScaledDecimal(parseWeiStr(checkpointedBalanceResult.principal), decimals);
      const balanceWithInterest = toScaledDecimal(parseWeiStr(recentBalanceResult), decimals);

      app.ports.giveMoneyMarketCustomerBalancePort.send({
        customerAddress: customerAddress,
        assetAddress: assetAddress,
        ledgerAccount: ledgerAccount,
        checkpointedBalance : checkpointedBalance,
        balanceWithInterest: balanceWithInterest});
    }).catch(reportError(app));
  });

  // port askMoneyMarketSupplyPort : { moneyMarketAddress : String, assetAddress : String, customerAddress : String, amountWeiStr : String } -> Cmd msg
  app.ports.askMoneyMarketSupplyPort.subscribe(({moneyMarketAddress, assetAddress, customerAddress, amountWeiStr}) => {
    const amountWei = parseWeiStr(amountWeiStr);

    wrapSend(
      app,
      eth, 
      MoneyMarket,
      moneyMarketAddress,
      "supply",
      [assetAddress, amountWei],
      assetAddress,
      customerAddress,
      currentSendGasPrice
    ).catch(reportError(app));
  });

  // port askMoneyMarketWithdrawPort : { moneyMarketAddress : String, assetAddress : String, customerAddress : String, amountWeiStr : String } -> Cmd msg
  app.ports.askMoneyMarketWithdrawPort.subscribe(({moneyMarketAddress, assetAddress, customerAddress, amountWeiStr}) => {
    const amountWei = parseWeiStr(amountWeiStr);

    wrapSend(
      app,
      eth,
      MoneyMarket,
      moneyMarketAddress,
      'withdraw',
      [assetAddress, amountWei],
      assetAddress,
      customerAddress,
      currentSendGasPrice,
      {
        displayArgs: [assetAddress, amountWei, customerAddress]
      }
    ).catch(reportError(app));
  });

  // port askMoneyMarketBorrowPort : { moneyMarketAddress : String, assetAddress : String, customerAddress : String, amountWeiStr : String } -> Cmd msg
  app.ports.askMoneyMarketBorrowPort.subscribe(({moneyMarketAddress, assetAddress, customerAddress, amountWeiStr}) => {
    const amountWei = parseWeiStr(amountWeiStr);

    wrapSend(
      app,
      eth,
      MoneyMarket,
      moneyMarketAddress,
      'borrow',
      [assetAddress, amountWei],
      assetAddress,
      customerAddress,
      currentSendGasPrice
    ).catch(reportError(app));
  });

  // port askMoneyMarketPayBorrowPort : { moneyMarketAddress : String, assetAddress : String, customerAddress : String, amountWeiStr : String } -> Cmd msg
  app.ports.askMoneyMarketPayBorrowPort.subscribe(({moneyMarketAddress, assetAddress, customerAddress, amountWeiStr}) => {
    const amountWei = parseWeiStr(amountWeiStr);

    wrapSend(
      app,
      eth,
      MoneyMarket,
      moneyMarketAddress,
      'repayBorrow',
      [assetAddress, amountWei],
      assetAddress,
      customerAddress,
      currentSendGasPrice
    ).catch(reportError(app));
  });

  // port askProtocolConfigPort : { blockNumber: Int, moneyMarketAddress : String } -> Cmd msg
  app.ports.askProtocolConfigPort.subscribe(({blockNumber, moneyMarketAddress}) => {
    wrapCall(
      app,
      eth,
      [ [ MoneyMarket, moneyMarketAddress, 'collateralRatio', [] ]
      , [ MoneyMarket, moneyMarketAddress, 'liquidationDiscount', [] ]
      , [ MoneyMarket, moneyMarketAddress, 'originationFee', [] ]
      ],
      blockNumber,
      `askProtocolConfigPort-${moneyMarketAddress}`
    ).then(([collateralRatioResult, liquidationDiscountResult, originationFeeResult]) => {
      app.ports.giveProtocolConfigPort.send({
        collateralRatio: toScaledDecimal(parseWeiStr(collateralRatioResult), EXP_DECIMALS),
        liquidationDiscount: toScaledDecimal(parseWeiStr(liquidationDiscountResult), EXP_DECIMALS),
        originationFee : toScaledDecimal(parseWeiStr(originationFeeResult), EXP_DECIMALS)
      });
    }).catch(reportError(app));
  }); 

  // port askAccountLimitsPort : { blockNumber : Int, moneyMarketAddress : String, customerAddress : String } -> Cmd msg
  app.ports.askAccountLimitsPort.subscribe(({blockNumber, moneyMarketAddress, customerAddress}) => {
    wrapCall(
      app,
      eth,
      [ [ MoneyMarket, moneyMarketAddress, 'getAccountLiquidity', [customerAddress] ]
      , [ MoneyMarket, moneyMarketAddress, 'calculateAccountValues', [customerAddress] ]
      ],
      blockNumber,
      `askAccountLimitsPort-${moneyMarketAddress}-${customerAddress}`
    ).then(([accountLiquidityResult, calculateAccountValuesResult]) => {
      app.ports.giveAccountLimitsPort.send({
        customerAddress: customerAddress,
        accountLiquidity: toScaledDecimal(parseWeiStr(accountLiquidityResult), EXP_DECIMALS),
        calculateTotalsSuccess : parseWeiStr(calculateAccountValuesResult[0]).isZero(),
        totalSupply : toScaledDecimal(parseWeiStr(calculateAccountValuesResult[1]), CALCULATE_ACCOUNT_VALUES_DECIMALS),
        totalBorrow: toScaledDecimal(parseWeiStr(calculateAccountValuesResult[2]), CALCULATE_ACCOUNT_VALUES_DECIMALS)
      });
    }).catch(reportError(app));
  });

  // port askBorrowersPort : { blockNumber : Int, moneyMarketAddress : String, borrowerAddresses : List String } -> Cmd msg
  app.ports.askBorrowersPort.subscribe(({blockNumber, moneyMarketAddress, borrowerAddresses}) => {
    const accountLiquidityCalls = borrowerAddresses.map((borrowerAddress) => {
      return [ MoneyMarket, moneyMarketAddress, 'getAccountLiquidity', [borrowerAddress] ];
    });

    wrapCall(
      app,
      eth,
      accountLiquidityCalls,
      blockNumber,
      `askBorrowersLiquidityPort-${moneyMarketAddress}`
    ).then((results) => {
      if (results) {
        app.ports.giveAccountLiquidityValuesPort.send(results.map((result, index) => {
          return {
            borrowerAddress: borrowerAddresses[index],
            value: toScaledDecimal(result, ETH_DECIMALS)
          };
        }));
      }
    }).catch(reportError(app));


    const accountValuesCalls = borrowerAddresses.map((borrowerAddress) => {
      return [ MoneyMarket, moneyMarketAddress, 'calculateAccountValues', [borrowerAddress] ];
    });

    wrapCall(
      app,
      eth,
      accountValuesCalls,
      blockNumber,
      `askBorrowersAccountValuesPort-${moneyMarketAddress}`
    ).then((results) => {
      if (results) {
        app.ports.giveAccountValuesPort.send(results.map((result, index) => {
          return {
            borrowerAddress: borrowerAddresses[index],
            isSuccess: result[0] === "0",
            sumSupplies: toScaledDecimal(result[1], CALCULATE_ACCOUNT_VALUES_DECIMALS),
            sumBorrows: toScaledDecimal(result[2], CALCULATE_ACCOUNT_VALUES_DECIMALS)
          };
        }));
      }
    }).catch(reportError(app));
  });

  // port askLiquidatePort : { moneyMarketAddress : String, customerAddress : String, borrowerAddress : String, borrowedAssetAddress : String, borrowedAssetAmountWeiStr : String, borrowedAssetDecimals : Int, desiredAssetAddress : String, desiredAssetDecimals : Int } -> Cmd msg
  app.ports.askLiquidatePort.subscribe(({moneyMarketAddress, customerAddress, borrowerAddress, borrowedAssetAddress, borrowedAssetAmountWeiStr, borrowedAssetDecimals, desiredAssetAddress, desiredAssetDecimals}) => {
    const amountWei = parseWeiStr(borrowedAssetAmountWeiStr);

    wrapSend(
      app,
      eth,
      MoneyMarket,
      moneyMarketAddress,
      'liquidateBorrow',
      [borrowerAddress, borrowedAssetAddress, desiredAssetAddress, amountWei],
      borrowedAssetAddress,
      customerAddress,
      currentSendGasPrice
    ).then((trxHash) => {
      app.ports.giveLiquidatePort.send({
        borrowerAddress: borrowerAddress,
        borrowedAssetAddress: borrowedAssetAddress,
        borrowedAmount: toScaledDecimal(amountWei, borrowedAssetDecimals),
        desiredCollateralAddress: desiredAssetAddress
      });
    }).catch(reportError(app));
  });

  // port askOraclePricePort : { blockNumber : Int, moneyMarketAddress : String, assetAddress : String } -> Cmd msg
  app.ports.askOraclePricePort.subscribe(({blockNumber, moneyMarketAddress, assetAddress}) => {
    wrapCall(
      app,
      eth,
      [
        [ MoneyMarket, moneyMarketAddress, 'assetPrices', [assetAddress] ]
      ],
      blockNumber,
      `askOraclePricePort-${moneyMarketAddress}-${assetAddress}`
    ).then(([result]) => {
      app.ports.giveOraclePricePort.send({
        assetAddress,
        value: toScaledDecimal(result, EXP_DECIMALS)
      });
    }).catch(reportError(app));
  });
}

function subscribeToLiquidatorPorts(app, eth) {
  // port askLiquidateByLiquidatorPort : { liquidatorAddress : String, customerAddress : String, borrowerAddress : String, borrowedAssetAddress : String, borrowedAssetAmountWeiStr : String, borrowedAssetDecimals : Int, desiredAssetAddress : String, desiredAssetDecimals : Int } -> Cmd msg
  app.ports.askLiquidateByLiquidatorPort.subscribe(({liquidatorAddress, customerAddress, borrowerAddress, borrowedAssetAddress, borrowedAssetAmountWeiStr, borrowedAssetDecimals, desiredAssetAddress, desiredAssetDecimals}) => {
    const amountWei = parseWeiStr(borrowedAssetAmountWeiStr);

    wrapSend(
      app,
      eth,
      Liquidator,
      liquidatorAddress,
      'liquidateBorrow',
      [borrowerAddress, borrowedAssetAddress, desiredAssetAddress, amountWei],
      borrowedAssetAddress,
      customerAddress,
      currentSendGasPrice
    ).then((trxHash) => {
      app.ports.giveLiquidateByLiquidatorPort.send({
        borrowerAddress: borrowerAddress,
        borrowedAssetAddress: borrowedAssetAddress,
        borrowedAmount: toScaledDecimal(amountWei, borrowedAssetDecimals),
        desiredCollateralAddress: desiredAssetAddress
      });
    }).catch(reportError(app));
  });
}

function subscribeToFaucetToken(app, eth) {
  const allocationAmount = (100 * 1e18).toString();

  // port askFaucetTokenAllocatePort : { tokenAddress : String, customerAddress : String } -> Cmd msg
  app.ports.askFaucetTokenAllocatePort.subscribe(({tokenAddress, customerAddress}) => {
    wrapSend(
      app,
      eth,
      FaucetToken,
      tokenAddress,
      'allocateTo',
      [customerAddress, allocationAmount],
      tokenAddress,
      customerAddress,
      currentSendGasPrice,
      {
        displayName: 'allocate',
        displayArgs: []
      }
    ).catch(reportError(app));
  });

  // port askFaucetTokenApprovePort : { tokenAddress : String, moneyMarketAddress : String, customerAddress : String, yesOrNo : Boolean } -> Cmd msg
  app.ports.askFaucetTokenApprovePort.subscribe(({tokenAddress, moneyMarketAddress, customerAddress, yesOrNo}) => {
    const approvalAmount = yesOrNo ? -1 : 0;

    wrapSend(
      app,
      eth,
      FaucetToken,
      tokenAddress,
      'approve',
      [moneyMarketAddress, approvalAmount],
      tokenAddress,
      customerAddress,
      currentSendGasPrice
    ).catch(reportError(app));
  });

  // port askWrapEtherPort : { assetAddress : String, customerAddress : String, amountWeiStr : String } -> Cmd msg
  app.ports.askWrapEtherPort.subscribe(({assetAddress, customerAddress, amountWeiStr}) => {
    const amountWei = parseWeiStr(amountWeiStr);

    wrapSend(
      app,
      eth,
      WETH9,
      assetAddress,
      'deposit',
      [],
      assetAddress,
      customerAddress,
      currentSendGasPrice,
      {
        value: amountWei,
        displayName: 'wrap',
        displayArgs: [assetAddress, customerAddress, amountWei]
      },
    ).catch(reportError(app));
  });

  // port askUnwrapEtherPort : { assetAddress : String, customerAddress : String, amountWeiStr : String } -> Cmd msg
  app.ports.askUnwrapEtherPort.subscribe(({assetAddress, customerAddress, amountWeiStr}) => {
    const amountWei = parseWeiStr(amountWeiStr);

    wrapSend(
      app,
      eth,
      WETH9,
      assetAddress,
      'withdraw',
      [amountWei],
      assetAddress,
      customerAddress,
      currentSendGasPrice,
      {
        displayName: 'unwrap',
        displayArgs: [assetAddress, customerAddress, amountWei]
      }
    ).catch(reportError(app));
  });
}

function subscribeToAskTokenBalance(app, eth) {
  // port askTokenBalanceTokenPort : { blockNumber : Int, assetAddress : String, customerAddress : String } -> Cmd msg
  app.ports.askTokenBalanceTokenPort.subscribe(({blockNumber, assetAddress, customerAddress}) => {
    const decimals = getDecimals(app, assetAddress);

    wrapCall(
      app,
      eth,
      [
        [ EIP20Interface, assetAddress, 'balanceOf', [customerAddress] ]
      ],
      blockNumber,
      `askTokenBalanceTokenPort-${assetAddress}-${customerAddress}`
    ).then(([result]) => {
      const balance = toScaledDecimal(result, decimals);

      app.ports.giveTokenBalanceTokenPort.send({
        assetAddress: assetAddress,
        customerAddress: customerAddress,
        balance: balance
      });
    }).catch(reportError(app));
  });
}

function subscribeToAskAccountBalance(app, eth) {
  // port askAccountBalancePort : { customerAddress : String } -> Cmd msg
  app.ports.askAccountBalancePort.subscribe(({customerAddress}) => {
    getBalance(eth, customerAddress).then(delay).then((result) => {
      const balance = toScaledDecimal(result, ETH_DECIMALS);

      app.ports.giveAccountBalancePort.send({
        balance: balance
      });
    }).catch(reportError(app));
  });
}

function subscribeToAskTokenAllowance(app, eth) {
  // port askTokenAllowanceTokenPort : { blockNumber : Int, assetAddress : String, contractAddress : String, customerAddress : String } -> Cmd msg
  app.ports.askTokenAllowanceTokenPort.subscribe(({blockNumber, assetAddress, contractAddress, customerAddress}) => {
    const decimals = getDecimals(app, assetAddress);

    wrapCall(
      app,
      eth,
      [
        [ EIP20Interface, assetAddress, 'allowance', [customerAddress, contractAddress] ]
      ],
      blockNumber,
      `askTokenAllowanceTokensPort-${assetAddress}-${contractAddress}-${customerAddress}`
    ).then(([result]) => {
      const allowance = toScaledDecimal(result, decimals);

      app.ports.giveTokenAllowanceTokenPort.send({
        assetAddress: assetAddress,
        contractAddress : contractAddress,
        customerAddress: customerAddress,
        allowance: allowance
      });
    }).catch(reportError(app));
  });
}

function subscribeToNewBlocks(app, eth) {
  var blockTimer;
  var previousBlock;

  function newBlockCheckFunction() {
    requestForeground(() => {
      getBlockNumber(eth).then((blockNumber) => {
        if (blockNumber && blockNumber !== previousBlock) {
          debug(`New Block: ${blockNumber}`);
          app.ports.giveNewBlockPort.send({block: blockNumber});
          previousBlock = blockNumber;
        }
      }).catch(reportError(app)).finally(() => {
        blockTimer = setTimeout(newBlockCheckFunction, NEW_BLOCK_CHECK_INTERVAL_MS);
      });
    });
  }

  // port askNewBlockPort : {} -> Cmd msg
  app.ports.askNewBlockPort.subscribe(newBlockCheckFunction);
}

// Sadly, polling is the only way to detect account
// changes from MetMask. For further read, see:
// https://github.com/MetaMask/metamask-extension/issues/1766#issuecomment-314844822
function subscribeToAccountChanges(app, eth) {
  var accountTimer;
  var previousAccount;

  function accountCheckFunction() {
    requestForeground(() => {
      getAccounts(eth).then(delay).then((accounts) => {
        const val = accounts[0] || null;

        if (previousAccount === undefined || val !== previousAccount) {
          app.ports.giveAccountPort.send({account: val});
          previousAccount = val;
        }
      }, reportError(app)).then(() => {
        accountTimer = setTimeout(accountCheckFunction, ACCOUNT_CHECK_INTERVAL_MS);
      });
    });
  }

  // port askAccountPort : {} -> Cmd msg
  app.ports.askAccountPort.subscribe(accountCheckFunction);
}

function subscribeToNetworkChanges(app, eth) {
  var networkTimer;
  var previousNetwork;

  function networkCheckFunction() {
    requestForeground(() => {
      getNetworkId(eth).then(delay).then((network) => {
        var val = parseInt(network);

        if (val == NaN) {
          val = null;
        }

        if (previousNetwork === undefined || val !== previousNetwork) {
          app.ports.giveNetworkPort.send({"network": val});
          previousNetwork = val;
        }

        setNetworkId(eth, val);
      }, reportError(app)).finally(() => {
        networkTimer = setTimeout(networkCheckFunction, NETWORK_CHECK_INTERVAL_MS);
      });
    });
  }

  // port askNetworkPort : {} -> Cmd msg
  app.ports.askNetworkPort.subscribe(networkCheckFunction);
}

function subscribeToCheckTrxStatus(app, eth) {
  // port checkTrxStatusPort : { blockNumber : Int, trxHash : String } -> Cmd msg
  app.ports.checkTrxStatusPort.subscribe(({blockNumber, trxHash}) => {
    const [bunchFail, bunchSuccess] = bunch(blockNumber, `checkTrxStatusPort-${trxHash}`);

    getTransactionReceipt(eth, trxHash).then(delay).then(bunchSuccess).then(receipt => handleReceipt(app, trxHash, blockNumber, receipt)).catch(bunchFail).catch(reportError(app));
  });
}

function subscribeToStoreTransaction(app, eth) {
  // port storeTransactionPort : { trxHash : String, networkId : Int, timestamp : Int, contractAddress : String, assetAddress : String, customerAddress : String, fun : String, args : List, status : Maybe Int, error : Maybe String } -> Cmd msg
  app.ports.storeTransactionPort.subscribe(({ trxHash, networkId, timestamp, contractAddress, assetAddress, customerAddress, fun, args, status, error }) => {
    transactionStorage.put(trxHash, networkId, timestamp, contractAddress, assetAddress, customerAddress, fun, args, status, error);
  });

  // port storeTransactionUpdatePort : { trxHash : String, status : Maybe Int, error : Maybe String } -> Cmd msg
  app.ports.storeTransactionUpdatePort.subscribe(({trxHash, status, error}) => {
    transactionStorage.update(trxHash, status, error);
  });

  // port askStoredTransactionsPort : {} -> Cmd msg
  app.ports.askStoredTransactionsPort.subscribe(({}) => {
    const transactions = Object.values(transactionStorage.getAll());

    app.ports.giveStoredTransactionsPort.send(transactions);
  });

  // port askClearTransactionsPort : {} -> Cmd msg
  app.ports.askClearTransactionsPort.subscribe(({}) => {
    transactionStorage.clear();
  });
}

function subscribeToPreferences(app, eth) {
  // port storePreferencesPort : { selectedAction : String, displayCurrency : String } -> Cmd msg
  app.ports.storePreferencesPort.subscribe((preferences) => {
    preferencesStorage.set(preferences);
  });

  // port askStoredPreferencesPort : {} -> Cmd msg
  app.ports.askStoredPreferencesPort.subscribe(() => {
    const preferences = preferencesStorage.get();

    app.ports.giveStoredPreferencesPort.send(preferences);
  });

  // port askClearPreferencesPort : {} -> Cmd msg
  app.ports.askClearPreferencesPort.subscribe(() => {
    preferencesStorage.set({});
  });
}

function subscribeToTermsAccepted(app, eth) {
  // port storeTermsAcceptedPort : { tosAccepted : Bool } -> Cmd msg
  app.ports.storeTermsAcceptedPort.subscribe(({tosAccepted}) => {
    termsAcceptedStorage.set(tosAccepted);
  });
}

function subscribeToSignMessage(app, eth) {
  // port signMessagePort : ( String, String ) -> Cmd msg
  app.ports.signMessagePort.subscribe(({messageToSign, customerAddress}) => {

    sign(eth, messageToSign, customerAddress).then(delay).then((result) => {
      // port giveSignedMessagePort : (Value -> msg) -> Sub msg
      app.ports.giveSignedMessagePort.send({
          customerAddress: customerAddress,
          message: messageToSign,
          signature: result
      });
    }).catch(async (error) => {
      if (isUserCancel(error)) {
        // TODO: Log user cancelled?
      } else {
        throw error;
      }
    }).catch(reportError(app));
  });
}

function subscribeToElectionStorage(app, eth) {
  // port storeVoteSubmittedPort : { networkName : String, customerAddress: String, electionId : String, voteValue : String } -> Cmd msg
  app.ports.storeVoteSubmittedPort.subscribe(({networkName, customerAddress, electionId, voteValue}) => {
    electionsVoteStorage.putVote(networkName, customerAddress, electionId, voteValue);
  });

  // port askStoredVotePort : { networkName : String, customerAddress: String, electionId : String }-> Cmd msg
  app.ports.askStoredVotePort.subscribe(({networkName, customerAddress, electionId}) => {
    const storedValue = electionsVoteStorage.getVote(networkName, customerAddress, electionId);

    if (storedValue) {
      app.ports.giveStoredVotePort.send({
        success: true,
        networkName: networkName,
        electionId: electionId,
        voteValue: storedValue
      });
    } else {
      app.ports.giveStoredVotePort.send({
        success: false,
        networkName: networkName,
        electionId: electionId,
        voteValue: ""
      });
    }
  });
}

function subscribeToGasService(app) {
  // port setGasPricePort : { amountWeiStr : String } -> Cmd msg
  app.ports.setGasPricePort.subscribe(({amountWeiStr}) => {
    const gasPriceWei = parseWeiStr(amountWeiStr);

    currentSendGasPrice = gasPriceWei;
  });
}

function subscribeToConsole(app) {
  app.ports.log.subscribe((msg) => {
    console.error(msg);
  });
}

function subscribe(app, trxProvider, dataProviders, networkMap, defaultNetwork) {
  const eth = makeEth(trxProvider, dataProviders, networkMap, defaultNetwork);

  failureEvent = getEvent(eth, MoneyMarket, "Failure");
  borrowLiquidatedEvent = getEvent(eth, MoneyMarket, "BorrowLiquidated");

  subscribeToConsole(app);
  subscribeToAccountChanges(app, eth);
  subscribeToNetworkChanges(app, eth);
  subscribeToMoneyMarketPorts(app, eth);
  subscribeToLiquidatorPorts(app, eth);
  subscribeToNewBlocks(app, eth);
  subscribeToFaucetToken(app, eth);
  subscribeToCheckTrxStatus(app, eth);
  subscribeToAskTokenBalance(app, eth);
  subscribeToAskAccountBalance(app, eth);
  subscribeToAskTokenAllowance(app, eth);
  subscribeToStoreTransaction(app, eth);
  subscribeToPreferences(app, eth);
  subscribeToTermsAccepted(app, eth);
  subscribeToSignMessage(app, eth);
  //subscribeToElectionStorage(app, eth);
  subscribeToGasService(app);
}

export default {
  subscribe
};
