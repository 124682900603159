import Web3_ from '../../node_modules/web3';
import BN from '../../node_modules/bn.js';

import bunch from './bunch';
import { at, fetchContract, getContractEvent } from './contract';
import { delay } from './delay';
import { debug, isUserCancel, providerType, PROVIDER_TYPE_COINBASE_WALLET, PROVIDER_TYPE_IM_TOKEN } from './utils';

const actionSubmittedTypeMap = {
  'supply': 0,
  'withdraw': 1,
  'borrow': 2,
  'repayBorrow': 3,
  'wrap': 4,
  'unwrap': 5
};

const DEFAULT_GAS_PRICE = 8000000000; // 8 Gwei

const COINBASE_WALLET_GAS_LIMIT = 317350;
const IM_TOKEN_GAS_LIMIT = 301896;

// Number of retries for an RPC call
const RETRIES = 5;
const RETRY_TIMEOUT = 1000;

const defaultCallParams = {};
const defaultSendParams = {};

const callProviderPromises = [];

function getBlockNumberForCall(blockNumber) {
  if (blockNumber === 0 || !blockNumber) {
    return "latest";
  } else {
    return blockNumber;
  }
}

function reverseObject(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    const val = obj[key];

    return {
      ...acc,
      [val]: key
    }
  }, {});
}

function makeEth(trxProvider, dataProviders, networkMap, defaultNetwork) {
  let trxWeb3;
  let isImToken = false;

  if (trxProvider) {
    trxWeb3 = new Web3_(trxProvider);
    trxWeb3.eth.type = 'send';
  }

  const dataWeb3 = new Web3_();
  dataWeb3.eth.type = 'call';

  return {
    trxWeb3: trxWeb3,
    dataWeb3: dataWeb3,
    dataProviders: dataProviders,
    readOnly: !!trxProvider,
    networkIdMap: reverseObject(networkMap),
    defaultNetworkId: networkMap[defaultNetwork],
    appProviderType: providerType(trxProvider)
  }
}

async function giveNewTrx(app, eth, contractAddress, assetAddress, customerAddress, result, funcName, args) {
  const networkId = parseInt(await getNetworkId(eth));

  app.ports.giveNewTrxPort.send({
    trxHash: result,
    network: networkId,
    timestamp: (+new Date()), // current epoch
    contract: contractAddress,
    asset: assetAddress,
    customer: customerAddress,
    function: funcName,
    args: args.map(a => a.toString())
  });

  if (funcName in actionSubmittedTypeMap) {
    app.ports.giveActionSubmittedPort.send(actionSubmittedTypeMap[funcName]);
  }
}

function wrapCall(app, eth, calls, blockNumber, bunchId, retries=RETRIES) {
  // Setup each call as a promise
  return withWeb3Eth(eth).then((web3Eth) =>{
    const callPromise = Promise.all(calls.map(([contractJson, address, method, args]) => {
      const contract = fetchContract(web3Eth, contractJson, address);

      return contract.methods[method](...args).call(defaultCallParams);
    }));

    const methodName = calls.map(([contract, address, method, args]) => method).join(",");

    // Retry will either be a function to recursively call `wrapCall` for more retries,
    // or will be undefined, which means "do not catch" as a `promise.then` argument.
    let retry;

    if (retries > 0) {
      retry = (error) => {
        debug(`Web3 returned "eth_call" error, method=${methodName}, retries=${retries}, error=${error.toString()}, blockNumber=${blockNumber}, bunchId=${bunchId}`);
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            wrapCall(app, eth, calls, blockNumber, bunchId, retries - 1).catch(reject).then(resolve);
          }, RETRY_TIMEOUT);
        });
      }
    } else {
      retry = undefined;
    }

    // Track success just informs us when we had to retry but later succeeded.
    let trackSuccess;

    if (retries < RETRIES) {
      trackSuccess = async function(res) {
        debug(`Success for ${methodName} on retry #${RETRIES - retries}`)
        return res;
      }
    } else {
      // Identity function
      trackSuccess = (res) => res;
    }

    // If we have a bunch id (that is, we want our results from many differently
    // invoked promises to all resolve at the same time), then we call bunch here.
    if (bunchId) {
      const [bunchFail, bunchSuccess] = bunch(
        blockNumber.toString(),
        bunchId
      );

      // In case of an error, this will call retry or, if we're on our last attempt,
      // call `bunchFail`
      return callPromise.then(delay).then(trackSuccess).then(bunchSuccess, retry || bunchFail);
    } else {
      return callPromise.then(delay).then(trackSuccess, retry);
    }
  });
}

function wrapSend(app, eth, contractJson, contractAddress, funcName, args, assetAddress, customerAddress, currentGasPrice, opts={}) {
  return withTrxWeb3(
    eth,
    (trxWeb3) => {
      return new Promise((resolve, reject) => {
        const contract = fetchContract(eth.trxWeb3.eth, contractJson, contractAddress);
        const defaultParamsClone = Object.assign({}, defaultSendParams);
        let sendParams;
        if (eth.appProviderType === PROVIDER_TYPE_COINBASE_WALLET) {
          sendParams = Object.assign(defaultParamsClone, { from: customerAddress, gasLimit: COINBASE_WALLET_GAS_LIMIT });
        } else if (eth.appProviderType === PROVIDER_TYPE_IM_TOKEN){
          sendParams = Object.assign(defaultParamsClone, { from: customerAddress, gasLimit: IM_TOKEN_GAS_LIMIT });
        } else {
          sendParams = Object.assign(defaultParamsClone, { from: customerAddress });
        }

        if (currentGasPrice !== undefined) {
          sendParams.gasPrice = currentGasPrice;
        } else {
          sendParams.gasPrice = DEFAULT_GAS_PRICE;
        }

        if (opts.value !== undefined) {
          sendParams.value = opts.value;
        }

        const sendArgs = args.map((arg) => {
          if (arg instanceof BN) {
            return arg.toString();
          } else {
            return arg;
          }
        });

        const promiEvent = contract.methods[funcName](...sendArgs).send(sendParams);

        promiEvent.on('error', (error) => {
          if (isUserCancel(error)) {
            // Swallow these errors, since they aren't really errors;
            resolve(null);
          } else {
            reject(error);
          }
        });

        promiEvent.on('transactionHash', (trxHash) => {
          delay().then(() => {
            if (trxHash) {
              const displayName = opts.displayName || funcName;
              const displayArgs = opts.displayArgs || args;

              giveNewTrx(app, eth, contractAddress, assetAddress, customerAddress, trxHash, displayName, displayArgs);

              resolve(trxHash);
            }
          })
        });
      });
    },
    () => {
      throw "Cannot send transaction without transactional Web3";
    }
  );
}

function withWeb3Eth(eth) {
  if (eth.dataWeb3._provider) {
    return Promise.resolve(eth.dataWeb3.eth);
  } else {
    return new Promise((resolve, reject) => {
      callProviderPromises.push(resolve);
    });
  }
}

function withTrxWeb3(eth, fnTrxWeb3, fnEls) {
  if (eth.trxWeb3) {
    return fnTrxWeb3(eth.trxWeb3);
  } else {
    return fnEls();
  }
}

function setNetworkId(eth, networkId) {
  if (eth.networkIdMap[networkId] && eth.dataProviders[eth.networkIdMap[networkId]]) {
    eth.dataWeb3.setProvider(eth.dataProviders[eth.networkIdMap[networkId]]);

    for (let promise of callProviderPromises) {
      // Resolve each promise that was enqueued
      promise(eth.dataWeb3.eth);
    }
  } else {
    eth.dataWeb3.setProvider(undefined);
  }
}

async function getNetworkId(eth) {
  return withTrxWeb3(
    eth,
    (trxWeb3) => trxWeb3.eth.net.getId(),
    () => eth.defaultNetworkId
  );
}

async function getBalance(eth, address) {
  const web3Eth = await withWeb3Eth(eth);

  return web3Eth.getBalance(address);
}

async function getBlockNumber(eth) {
  const web3Eth = await withWeb3Eth(eth);

  return web3Eth.getBlockNumber();
}

async function getAccounts(eth) {
  return withTrxWeb3(
    eth,
    (trxWeb3) => trxWeb3.eth.getAccounts(),
    () => []
  );
}

async function getTransactionReceipt(eth, trxHash) {
  const web3Eth = await withWeb3Eth(eth);

  return web3Eth.getTransactionReceipt(trxHash)
}

async function sign(eth, message, address) {
  return withTrxWeb3(
    eth,
    (trxWeb3) => trxWeb3.eth.personal.sign(Web3_.utils.fromUtf8(message), address),
    () => {
      throw "Cannot sign message without transactional Web3";
    }
  );
}

function getEvent(eth, contractJson, eventName) {
  // Note: We don't need this to be initialized with a provider
  const web3Eth = eth.dataWeb3.eth;

  return getContractEvent(web3Eth, contractJson, eventName);
}

export {
  getAccounts,
  getBalance,
  getBlockNumber,
  getEvent,
  getNetworkId,
  getTransactionReceipt,
  makeEth,
  setNetworkId,
  sign,
  withWeb3Eth,
  wrapCall,
  wrapSend
}