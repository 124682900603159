
import { debug } from './utils';

// Global store of active bunches
const bunches = {};

// Bunch takes a bunch Id and an event id, and returns two promises,
// one for success and one for failure. These should be called on
// a promise as: `originalPromise.catch(res[0]).then(res[1]).then(...)`.
// This will make that originalPromise() resolve only once all know
// existing promises with the same bunch id are resolved.
function bunch(bunchId, event) {
  if (!bunches[bunchId]) {
    bunches[bunchId] = {
      bunchResults: {}
    }
  }

  // debug(`Bunch ${bunchId}, event: ${event}`);

  // Add to our list of events
  bunches[bunchId].bunchResults[event] = null;

  async function dispatchResults(event, callback) {
    bunches[bunchId].bunchResults[event] = callback;
    let events = Object.keys(bunches[bunchId].bunchResults);

    // If all known bunch events are resolved, then resolve this promise.
    if (events.every((event) => !!bunches[bunchId].bunchResults[event])) {
      debug(`Dispatching "bunch ${bunchId}" with ${Object.keys(bunches[bunchId].bunchResults).length} values`);

      for (let event of events) {
        // debug(`Dispatching for ${bunchId} ${event}`);

        const callback = bunches[bunchId].bunchResults[event];

        callback();

        // Remove this event
        delete bunches[bunchId].bunchResults[event];
      }
    } else {
      // Continue waiting...
      // console.log(["Waiting on", events.filter((event) => !bunches[bunchId].bunchResults[event])]);
    }
  }

  // Track failure
  function failure(error) {
    return new Promise((resolve, reject) => {
      dispatchResults(event, reject.bind(null, error));
    });
  }

  // Track success
  function success(...results) {
    return new Promise((resolve, reject) => {
      dispatchResults(event, resolve.bind(null, ...results));
    });
  }

  return [failure, success];
};

export default bunch;