import Web3 from '../../node_modules/web3';
import '../css-build/main.css';
import { Elm } from '../elm/Main.elm';
import registerServiceWorker from './registerServiceWorker';
import ports from './ports';
import storage from './storage';
import { handleError, startSession } from './analytics';
import { providerType } from './utils';

var oldErrorHandler = window.onerror || function(){};

window.onerror = function(message, source, lineno, colno, error) {
  handleError(message, source, lineno, colno, error);
  oldErrorHandler(message, source, lineno, colno, error);
  return true;
};

window.addEventListener('DOMContentLoaded', startSession);

window.addEventListener('load', function() {
  let trxProvider;

  // Set provider, possibly via MetaMask
  if (window.ethereum) {
    window.web3 = new Web3(ethereum);
    try {
        // Request account access if needed
        window.ethereum.enable();

        trxProvider = window.web3.currentProvider;
    } catch (error) {
        // User denied account access...
    }
  }
  else if (typeof(web3) !== 'undefined' && web3.currentProvider) {
    trxProvider = web3.currentProvider;
  }

  const dataProviders = Object.entries(process.env.DATA_PROVIDERS).reduce((acc, [network, url]) => {
    return Object.assign(acc, {[network]: new Web3.providers.HttpProvider(url)});
  }, {});

  const configFiles = process.env.CONFIG_FILES;

  const didAcceptTOS = storage("tosAcceptance").get(false);

  const app = Elm.Main.init({
    node: document.getElementById('root'),
    flags: {
      configurations: configFiles,
      apiBaseUrlMap: process.env.API_BASE_URL_MAP,
      userAgent: navigator.userAgent,
      providerType: providerType(trxProvider),
      acceptedTOS : didAcceptTOS,
      electionsConfig: process.env.ELECTIONS_CONFIG,
    }
  });

  registerServiceWorker();

  ports.subscribe(app, trxProvider, dataProviders, process.env.NETWORK_MAP, process.env.DEFAULT_NETWORK);
});
