import createStorage from './storage';

function electionStorage(storageKey) {
  const storage = createStorage(storageKey);

  function getVoteHistoryKey(networkName, customerAddress, electionId) {
    return networkName + "_" + customerAddress + "_" + electionId;
  }

  function putVote(networkName, customerAddress, electionId, voteValue) {
    const voteHistory = storage.get({});
    const voteKey = getVoteHistoryKey(networkName, customerAddress, electionId);

    voteHistory[voteKey] = voteValue;
    storage.set(voteHistory);
  }

  function getVote(networkName, customerAddress, electionId) {
    const voteHistory = storage.get({});
    const voteKey = getVoteHistoryKey(networkName, customerAddress, electionId);

    return voteHistory[voteKey];
  }

  function clear() {
    storage.clear();

    return true;
  }

  return {
    putVote,
    getVote,
    clear
  }
}

export default electionStorage;